(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name cycoding.directive:headerDirective
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="cycoding">
       <file name="index.html">
        <header-directive></header-directive>
       </file>
     </example>
   *
   */
  angular
    .module('cycoding')
    .directive('headerDirective', headerDirective);

  function headerDirective() {
    return {
      restrict: 'EA',
      scope: {},
      template: cyTemplates['directives/header-directive-directive.tpl.html'],
      replace: true,
      controller: "AppCtrl",
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
