(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cycoding.controller:AppCtrl
   * @requires $scope
   *
   * @description
   *
   */
  angular
    .module('cycoding')
    .controller('AppCtrl', AppCtrl);

  AppCtrl.$inject = ["$scope", "$mdSidenav", "RedirectionService"];
  function AppCtrl($scope, $mdSidenav, RedirectionService) {

    $scope.showSideMenu = function(link){
      $mdSidenav("main").toggle();
      if(link){
        RedirectionService.goTo(link, true);
      }
    };

    $scope.goToHome = function(){
      var path = "/";
      if(RedirectionService.path()!==path){
        RedirectionService.goTo(path, true);
      }
    };

    $scope.navItems =
      [
        {
          "name" : "Home",
          "href" : "/"
        },
        {
          "name" : "About Me",
          "href" : "/aboutme"
        },
        {
          "name" : "Working Experience",
          "href" : "/workingexperience"
        },
        {
          "name" : "Education",
          "href" : "/education"
        },
        {
          "name" : "Works",
          "href" : "/works"
        },
        {
          "name" : "Contact",
          "href" : "/contact"
        }
        // {
        //   "name" : "about",
        //   "href" : "/#/"
        // },
        // {
        //   "name" : "about",
        //   "href" : "/#/"
        // }
      ]
  }
}());
