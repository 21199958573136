var cyTemplates = { 
'directives/header-directive-directive.tpl.html': '<div flex="none"><md-toolbar layout="row" id="cy-title" class="md-hue-1 md-toolbar-tools" aria-lable="Title bar"><md-button ng-click="showSideMenu()" class="md-icon-button md-ink-ripple" aria-label="Menu Button"><md-icon md-svg-icon="images/svg/menu.svg"></md-icon></md-button><div flex><div layout="row" layout-align="center center"><span ng-click="goToHome()" id="cy-header">cycoding</span></div></div><md-menu md-position-mode="target-right target"><md-button class="md-icon-button md-ink-ripple" ng-click="$mdOpenMenu($event)" type="button" aria-label="More"><md-icon md-svg-icon="images/svg/more.svg" aria-hidden="true"></md-icon></md-button><md-menu-content><md-menu-item><md-button class="md-primary" target="_blank" ng-href="https://www.linkedin.com/in/chao-yang-r"><md-icon md-svg-src="images/svg/linkedin.svg" aria-hidden="true"></md-icon>LinkedIn</md-button></md-menu-item><md-menu-item><md-button class="md-primary" aria-label="View Source on GitHub" target="_blank" ng-href="https://github.com/cyang6/cycoding"><md-icon md-svg-src="images/svg/github.svg" aria-hidden="true"></md-icon>GitHub</md-button></md-menu-item><md-menu-item><md-button class="md-primary" aria-label="Send Email" ng-href="mailto:cyang05@mail.roosevelt.edu"><md-icon md-svg-src="images/svg/email.svg" aria-hidden="true"></md-icon>E-Mail</md-button></md-menu-item></md-menu-content></md-menu></md-toolbar><md-sidenav id="main-side" layout="column" md-component-id="main" class="md-sidenav-left no-text-transform"><md-button ng-click="showSideMenu(item.href)" class="md-primary md-hue-1 md-raised" ng-repeat="item in navItems">{{item.name}}</md-button></md-sidenav></div>',
'about/directives/about-me-directive.tpl.html': '<div>{{aboutMe.name}}</div>',
'about/views/about.tpl.html': '<div><div><h3 style="text-align:center">My Career</h3></div><div><div ng-repeat="msg in msgs"><p class="cy-box">{{msg.paragraph}}</p></div></div></div>',
'contact/views/contact.tpl.html': '<div class="cy-box" layout="row"><div flex="nogrow"><h5>Name:</h5><h5>Email:</h5><h5>Address:</h5></div><div flex class="cy-contact"><h5>{{contact.name}}</h5><h5><a href="mailto:{{contact.email}}">{{contact.email}}</a></h5><div class="cy-address"><h5>{{contact.address.street}}</h5><h5>{{contact.address.city}}, {{contact.address.state}} {{contact.address.zipCode}}</h5></div></div></div>',
'education/views/education.tpl.html': '<div><h3 style="text-align:center">{{education.title}}</h3></div><div ng-repeat="school in education.schools"><h4>{{school.name}}</h4><h5>Location: {{school.location}}</h5><h5>{{school.degree}} in {{school.major}}</h5><h5>Date of Graduation: {{school.dog}}</h5><div ng-repeat="section in school.sections" class="cy-box"><h5>{{section.name}}</h5><ul><li ng-repeat="msg in section.paragraphs"><span ng-if="msg.label" class="bold">{{msg.label}}: </span>{{msg.paragraph}}</li></ul></div><div ng-if="school.link"><h6>{{school.link.name}}: <a ng-href="{{school.link.url}}">{{school.link.url}}</a></h6></div><hr ng-if="!$last" class="cy-divider"></div>',
'home/views/home.tpl.html': '<div class="cy-box" flex="none" layout="column" layout-align="center center"><div id="slide-container" ng-click="goToWorks()"><uib-carousel interval="myInterval" no-wrap="false" active="active"><uib-slide ng-repeat="slide in slides track by slide.id" index="slide.id"><img ng-src="{{slide.image}}" style="margin:auto"><div class="carousel-caption"><h5>{{slide.text}}</h5></div></uib-slide></uib-carousel></div></div><div><h4 class="cy-title">About Me:</h4><p class="cy-box">My name is Chao Yang. I\'m currently working at Mobile e-commerce Department of Sears Holdings Corporation located in Chicago, IL</p><p class="cy-box">The image carousel above shows the screenshots of some of the components which I work on during my time in Sears as a mobile website developer. Click the <span class="cy-hint">up left menu</span> for more information on me or check <span class="cy-hint">below</span> for links to my LinkedIn profile and GitHub repo for this website.</p><p class="cy-box">I\'m currently looking for new jobs. Please contact me via e-mail <a href="mailto:cyang05@mail.roosevelt.edu">cyang05@mail.roosevelt.edu</a></p><div layout="row" class="no-text-transform"><md-button flex="none" class="md-primary" ng-href="https://www.linkedin.com/in/chao-yang-r"><md-icon md-svg-src="images/svg/linkedin.svg" aria-hidden="true"></md-icon>LinkedIn</md-button><md-button flex="none" class="md-primary" aria-label="View Source on GitHub" ng-href="https://github.com/cyang6/cycoding"><md-icon md-svg-src="images/svg/github.svg" aria-hidden="true"></md-icon>GitHub</md-button></div><h4 class="cy-title">Links to the Sears websites I work on:</h4><div class="cy-box" style="margin-bottom: 20px"><p><a href="http://m.sears.com">http://m.sears.com</a></p><p><a href="http://m.kmart.com">http://m.kmart.com</a></p></div><h6>*Best viewed on mobile devices.</h6></div>',
'working-experience/views/working-experience.tpl.html': '<div><h3 style="text-align:center">{{msgs.title}}</h3></div><h4>{{msgs.company}}</h4><div ng-repeat="section in msgs.sections" class="cy-box"><h4>{{section.name}}</h4><ul><li ng-repeat="msg in section.text">{{msg.paragraph}}<a ng-if="msg.phone" ng-href="tel:{{msg.phone}}">{{msg.phone}}</a></li></ul></div>',
'works/views/works.tpl.html': '<div><h3 style="text-align:center">{{works.title}}</h3><h4>{{works.intro}}<a ng-href="{{works.site}}">{{works.site}}</a></h4></div><div ng-repeat="section in works.sections"><hr class="cy-divider"><h4>{{section.name}}</h4><div class="cy-section"><div class="cy-box"><img class="cy-sc" ng-src="{{section.image}}"></div><div class="cy-box"><ul><li ng-repeat="msg in section.paragraphs">{{msg.paragraph}}</li></ul></div></div></div>',
 'dummy': 'dummy'};(function () {
  'use strict';

  /* @ngdoc object
   * @name works
   * @description
   *
   */
  angular
    .module('works', [
      'ngRoute'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('works')
    .config(config);

  function config($routeProvider) {
    $routeProvider
      .when('/works', {
        template: cyTemplates['works/views/works.tpl.html'],
        controller: 'WorksCtrl',
        resolve: {
          "aboutme": ["$ocLazyLoad", function($ocLazyLoad) {
            return $ocLazyLoad.load('js/lazy/works.js');
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name workingExperience
   * @description
   *
   */
  angular
    .module('workingExperience', [
      'ngRoute'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('workingExperience')
    .config(config);

  function config($routeProvider) {
    $routeProvider
      .when('/workingexperience', {
        template: cyTemplates['working-experience/views/working-experience.tpl.html'],
        controller: 'WorkingExperienceCtrl',
        resolve: {
          "aboutme": ["$ocLazyLoad", function($ocLazyLoad) {
            return $ocLazyLoad.load('js/lazy/working-experience.js');
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name home
   * @description
   *
   */
  angular
    .module('home', [
      'ngRoute'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('home')
    .config(config);

  function config($routeProvider) {
    $routeProvider
      .when('/', {
        template: cyTemplates['home/views/home.tpl.html'],
        controller: 'HomeCtrl',
        resolve: {
          "homeCtrl": ["$ocLazyLoad", function($ocLazyLoad) {
            return $ocLazyLoad.load('js/lazy/home.js');
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name education
   * @description
   *
   */
  angular
    .module('education', [
      'ngRoute'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('education')
    .config(config);

  function config($routeProvider) {
    $routeProvider
      .when('/education', {
        template: cyTemplates['education/views/education.tpl.html'],
        controller: 'EducationCtrl',
        resolve: {
          "aboutme": ["$ocLazyLoad", function($ocLazyLoad) {
            return $ocLazyLoad.load('js/lazy/education.js');
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name contact
   * @description
   *
   */
  angular
    .module('contact', [
      'ngRoute'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('contact')
    .config(config);

  function config($routeProvider) {
    $routeProvider
      .when('/contact', {
        template: cyTemplates['contact/views/contact.tpl.html'],
        controller: 'ContactCtrl',
        resolve: {
          "aboutme": ["$ocLazyLoad", function($ocLazyLoad) {
            return $ocLazyLoad.load('js/lazy/contact.js');
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name about
   * @description
   *
   */
  angular
    .module('about', [
      'ngRoute'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('about')
    .config(config);

  function config($routeProvider) {
    $routeProvider
      .when('/aboutme', {
        template: cyTemplates['about/views/about.tpl.html'],
        controller: 'AboutCtrl',
        resolve: {
          "aboutme": ["$ocLazyLoad", function($ocLazyLoad) {
            return $ocLazyLoad.load('js/lazy/about.js');
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name cycoding
   * @description
   *
   */
  window.cytemplates = {};
  angular
    .module('cycoding', [
      'oc.lazyLoad',
      'ngTouch',
      'ngRoute',
      'ui.bootstrap',
      'ngMaterial',
      'home',
      'about',
      'workingExperience',
      'education',
      'works',
      'contact'
    ])
  //   .run(['$templateCache', function($templateCache) {
  //   for (var i = 0; i < cyTemplates.length; i++ ){
  //     var template = cyTemplates[i];
  //     var key = Object.keys(template)[0];
  //     $templateCache.put(key,cyTemplates[key]);
  //   }
  // }])
  ;
}());

(function () {
  'use strict';

  angular
    .module('cycoding')
    .config(config);

  function config($routeProvider, $locationProvider, $mdThemingProvider) {
    //$locationProvider.html5Mode(true).hashPrefix("#");
    $routeProvider.otherwise({
      redirectTo: '/'
    });
    $mdThemingProvider.theme('default')
      .primaryPalette('blue',{
      'default': 'A700', // by default use shade 400 from the pink palette for primary intentions
        'hue-1': 'A400', // use shade 100 for the <code>md-hue-1</code> class
        'hue-2': 'A200', // use shade 600 for the <code>md-hue-2</code> class
        'hue-3': 'A100' // use shade A100 for the <code>md-hue-3</code> class
    })
      .accentPalette('light-blue');
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cycoding.factory:Cy
   *
   * @description
   *
   */
  angular
    .module('cycoding')
    .factory('Cy', Cy);

  function Cy() {
    var CyBase = {};
    CyBase.someValue = 'Cy';
    CyBase.someMethod = function () {
      return 'Cy';
    };
    return CyBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cycoding.service:RedirectionService
   *
   * @description
   *
   */
  angular
    .module('cycoding')
    .service('RedirectionService', RedirectionService);

  function RedirectionService($location, $anchorScroll) {
    var self = this;
    self.path = function () {
      return $location.path();
    };

    self.goTo = function (url, needToScrollToTop) {
      if(needToScrollToTop){
        $("#cy-container").scrollTop(0);
      }
      $location.path(url);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name cycoding.directive:headerDirective
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="cycoding">
       <file name="index.html">
        <header-directive></header-directive>
       </file>
     </example>
   *
   */
  angular
    .module('cycoding')
    .directive('headerDirective', headerDirective);

  function headerDirective() {
    return {
      restrict: 'EA',
      scope: {},
      template: cyTemplates['directives/header-directive-directive.tpl.html'],
      replace: true,
      controller: "AppCtrl",
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cycoding.controller:AppCtrl
   * @requires $scope
   *
   * @description
   *
   */
  angular
    .module('cycoding')
    .controller('AppCtrl', AppCtrl);

  AppCtrl.$inject = ["$scope", "$mdSidenav", "RedirectionService"];
  function AppCtrl($scope, $mdSidenav, RedirectionService) {

    $scope.showSideMenu = function(link){
      $mdSidenav("main").toggle();
      if(link){
        RedirectionService.goTo(link, true);
      }
    };

    $scope.goToHome = function(){
      var path = "/";
      if(RedirectionService.path()!==path){
        RedirectionService.goTo(path, true);
      }
    };

    $scope.navItems =
      [
        {
          "name" : "Home",
          "href" : "/"
        },
        {
          "name" : "About Me",
          "href" : "/aboutme"
        },
        {
          "name" : "Working Experience",
          "href" : "/workingexperience"
        },
        {
          "name" : "Education",
          "href" : "/education"
        },
        {
          "name" : "Works",
          "href" : "/works"
        },
        {
          "name" : "Contact",
          "href" : "/contact"
        }
        // {
        //   "name" : "about",
        //   "href" : "/#/"
        // },
        // {
        //   "name" : "about",
        //   "href" : "/#/"
        // }
      ]
  }
}());
