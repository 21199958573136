(function () {
  'use strict';

  /* @ngdoc object
   * @name cycoding
   * @description
   *
   */
  window.cytemplates = {};
  angular
    .module('cycoding', [
      'oc.lazyLoad',
      'ngTouch',
      'ngRoute',
      'ui.bootstrap',
      'ngMaterial',
      'home',
      'about',
      'workingExperience',
      'education',
      'works',
      'contact'
    ])
  //   .run(['$templateCache', function($templateCache) {
  //   for (var i = 0; i < cyTemplates.length; i++ ){
  //     var template = cyTemplates[i];
  //     var key = Object.keys(template)[0];
  //     $templateCache.put(key,cyTemplates[key]);
  //   }
  // }])
  ;
}());
