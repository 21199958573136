(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cycoding.service:RedirectionService
   *
   * @description
   *
   */
  angular
    .module('cycoding')
    .service('RedirectionService', RedirectionService);

  function RedirectionService($location, $anchorScroll) {
    var self = this;
    self.path = function () {
      return $location.path();
    };

    self.goTo = function (url, needToScrollToTop) {
      if(needToScrollToTop){
        $("#cy-container").scrollTop(0);
      }
      $location.path(url);
    };
  }
}());
