(function () {
  'use strict';

  angular
    .module('workingExperience')
    .config(config);

  function config($routeProvider) {
    $routeProvider
      .when('/workingexperience', {
        template: cyTemplates['working-experience/views/working-experience.tpl.html'],
        controller: 'WorkingExperienceCtrl',
        resolve: {
          "aboutme": ["$ocLazyLoad", function($ocLazyLoad) {
            return $ocLazyLoad.load('js/lazy/working-experience.js');
          }]
        }
      });
  }
}());
