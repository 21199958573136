(function () {
  'use strict';

  angular
    .module('about')
    .config(config);

  function config($routeProvider) {
    $routeProvider
      .when('/aboutme', {
        template: cyTemplates['about/views/about.tpl.html'],
        controller: 'AboutCtrl',
        resolve: {
          "aboutme": ["$ocLazyLoad", function($ocLazyLoad) {
            return $ocLazyLoad.load('js/lazy/about.js');
          }]
        }
      });
  }
}());
