(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cycoding.factory:Cy
   *
   * @description
   *
   */
  angular
    .module('cycoding')
    .factory('Cy', Cy);

  function Cy() {
    var CyBase = {};
    CyBase.someValue = 'Cy';
    CyBase.someMethod = function () {
      return 'Cy';
    };
    return CyBase;
  }
}());
