(function () {
  'use strict';

  /* @ngdoc object
   * @name contact
   * @description
   *
   */
  angular
    .module('contact', [
      'ngRoute'
    ]);
}());
