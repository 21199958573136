(function () {
  'use strict';

  angular
    .module('education')
    .config(config);

  function config($routeProvider) {
    $routeProvider
      .when('/education', {
        template: cyTemplates['education/views/education.tpl.html'],
        controller: 'EducationCtrl',
        resolve: {
          "aboutme": ["$ocLazyLoad", function($ocLazyLoad) {
            return $ocLazyLoad.load('js/lazy/education.js');
          }]
        }
      });
  }
}());
