(function () {
  'use strict';

  angular
    .module('cycoding')
    .config(config);

  function config($routeProvider, $locationProvider, $mdThemingProvider) {
    //$locationProvider.html5Mode(true).hashPrefix("#");
    $routeProvider.otherwise({
      redirectTo: '/'
    });
    $mdThemingProvider.theme('default')
      .primaryPalette('blue',{
      'default': 'A700', // by default use shade 400 from the pink palette for primary intentions
        'hue-1': 'A400', // use shade 100 for the <code>md-hue-1</code> class
        'hue-2': 'A200', // use shade 600 for the <code>md-hue-2</code> class
        'hue-3': 'A100' // use shade A100 for the <code>md-hue-3</code> class
    })
      .accentPalette('light-blue');
  }
}());
