(function () {
  'use strict';

  angular
    .module('home')
    .config(config);

  function config($routeProvider) {
    $routeProvider
      .when('/', {
        template: cyTemplates['home/views/home.tpl.html'],
        controller: 'HomeCtrl',
        resolve: {
          "homeCtrl": ["$ocLazyLoad", function($ocLazyLoad) {
            return $ocLazyLoad.load('js/lazy/home.js');
          }]
        }
      });
  }
}());
