(function () {
  'use strict';

  /* @ngdoc object
   * @name education
   * @description
   *
   */
  angular
    .module('education', [
      'ngRoute'
    ]);
}());
